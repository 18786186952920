<template>
    <div class="card-tail card-f-h">
        <div class="flex flex-row-reverse">
            <el-button type="primary" size="mini" @click="setCurrent(), $emit('createNew')">Skapa ny</el-button>
        </div>
        <el-table ref="singleTable" :data="personList" highlight-current-row @current-change="handleCurrentChange" v-loading="$waiting.is('loading')">
            <el-table-column type="index" width="50" />
            <el-table-column property="name" label="Namn" width="230" />
            <el-table-column property="civicNumber" label="Pers.nr." width="120" />
            <el-table-column prop="ownershipShare" label="Ägarandel" width="120">
                <template slot-scope="scope">
                    <p>
                        {{ editOwnershipVal(scope.row.ownershipShare) }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column prop="isOwner" label="Huvudman">
                <template slot-scope="scope">
                    <p>
                        {{ showOwner(scope.row.isOwner) }}
                    </p>
                </template>
            </el-table-column>
        </el-table>
        <div class="py-5">
            <el-button @click="goBack()">Tillbaka</el-button>
        </div>
    </div>
</template>
<script>
import Api from "../clients.api";
export default {
    data() {
        return {
            personList: [],
        };
    },

    created() {
        this.getPersonList();
    },

    methods: {
        showOwner(item) {
            if (item) {
                return "Ja";
            }
        },

        editOwnershipVal(item) {
            if (item == 0) {
                return "";
            }
            return item.toString().replace(".", ",");
        },

        async getPersonList() {
            this.$waiting.start("loading");
            this.personList = await Api.getPersons(this.$route.params.clientId);
            this.$waiting.end("loading");
        },

        setCurrent(row) {
            this.$refs.singleTable.setCurrentRow(row);
        },

        handleCurrentChange(val) {
            if (val != null) {
                this.$emit("clicked", val);
            }
        },
        goBack() {
            this.$router.push({ path: "/clientList" });
        },
    },
};
</script>
